<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input style="width: 200px;" placeholder="搜索商品名称" v-model="params.keywords" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="搜索买家" v-model="params.user_id" :remote-method="remoteUsers" :loading="remote_loading" remote filterable clearable default-first-option>
						<el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getCarts({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="carts_table" class="bill scroll-wrapper absolute" height="100%" :data="carts" :size="theme.size">
				<el-table-column fixed="left" type="selection" width="42"></el-table-column>
				<!-- <el-table-column prop="img" label="缩略图" width="60">
					<el-image slot-scope="scope" style="width: 40px; height: 40px;" :src="scope.row.img" fit="fit"></el-image>
				</el-table-column> -->
				<el-table-column prop="shop.name" label="店铺" width="80" v-if="!admin_user.shop_id"></el-table-column>
				<el-table-column prop="user.name" label="买家" width="80"></el-table-column>
				<el-table-column prop="title" label="商品" min-width="240">
					<template slot-scope="scope">
						<p>{{scope.row.title}}</p>
						<p>{{scope.row.attribute_name}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="operation_at" label="小计" width="240">
					<template slot-scope="scope">
						<p v-if="!scope.row.goods">此商品已删除</p>
						<template v-else>
							<p>价格：{{(scope.row.shop_price/100).toFixed(2)}} x{{scope.row.count}} <b style="color: #ff0000;">{{((scope.row.shop_price*scope.row.count)/100).toFixed(2)}}</b> 元</p>
							<p>重量：{{scope.row.goods.weight}} x{{scope.row.count}} <b>{{(scope.row.goods.weight*scope.row.count)}}</b> 克</p>
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="operation_at" label="操作时间" width="220" v-if="theme.width >= 1080">
					<template slot-scope="scope">
						<p v-if="scope.row.deleted_at">删除：{{scope.row.deleted_at}}</p>
						<template v-else>
							<p>创建：{{scope.row.created_at}}</p>
							<p>更新：{{scope.row.updated_at}}</p>
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="operation_at" label="更多操作" width="140">
					<el-dropdown slot="header" split-button type="primary" size="mini" @command="onAction">
						批量操作
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="closing" :disabled="!$utils.checkAuth('carts', 'closing')">结算选中</el-dropdown-item>
							<el-dropdown-item command="copying" :disabled="!$utils.checkAuth('carts', 'copying')">复制选中</el-dropdown-item>
							<el-dropdown-item command="export">导出选中</el-dropdown-item>
							<el-dropdown-item command="delete" :disabled="!$utils.delete('carts')">删除选中</el-dropdown-item>
							<el-dropdown-item command="restore" :disabled="!$utils.restore('carts')">恢复选中</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<template slot-scope="scope">
						<template v-if="!scope.row.deleted_at">
							<el-button type="text" @click="onEdit(scope.row)" :disabled="!$utils.update('carts') || !scope.row.goods">编辑</el-button>
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('carts')">删除</el-button>
						</template>
						<el-button type="text" @click="reStore(scope.row)" :disabled="!$utils.restore('carts')" v-else>恢复</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getCarts({ ...params, page: 1, perPage: v }); $refs.carts_table.bodyWrapper.scrollTop = 0; }"
				@current-change="(v) => { getCarts({ ...params, page: v }); $refs.carts_table.bodyWrapper.scrollTop = 0; }"
				background>	
			</el-pagination>
		</main-table>
		<el-dialog width="800px" title="结算中心【销售端-业务员导购】" :visible.sync="dialog_closing" :before-close="clearCartConfirm">
			<el-alert type="warning" style="margin-top: -20px; margin-bottom: 20px;" title="关于销售导购操作说明">
				<template solt="description">
					<p style="line-height: 1.5;">1、使用自己账户登陆商城【用户端】，把商品添加到购物车，再到此页面选中复制到跟进的客户账户</p>
					<p style="line-height: 1.5;">2、直接从其他已成交客户购物车中复制到当前跟进中客户</p>
				</template>
			</el-alert>
			<el-form ref="form_closing" label-width="100px" :model="cart_form" :size="theme.size">
				<el-form-item label="顾客账户" prop="user_id" :rules="{ required: true, message: '必须提交结算用户', trigger: 'blur' }">{{cart_form.user_name}}</el-form-item>
				<el-form-item label="结算清单" required>{{cart_form.goods_count}}款，共 {{cart_form.goods_total}}件，约 {{cart_form.weight}}克，合计 <b style="color: #ff0000;">￥{{cart_form.goods_price/100}}</b>元</el-form-item>
				<template v-if="cart_form.weight">
					<el-form-item label="配送地址" prop="address_id" :rules="{ required: true, message: '必须提交配送地址', trigger: 'blur' }">
						<el-radio-group v-model="cart_form.address_id" v-if="addresses.length">
							<el-radio style="margin-top: 8px;" v-for="address in addresses" :label="address.id" :key="address.id">
								{{address.attn}}，{{address.mobile}}，<sapn v-for="region in address.regions" :key="region.id">{{region.name}}</sapn>{{address.detail}}
							</el-radio>
						</el-radio-group>
						<p style="color: #999;" v-else>暂无地址选择。请联系客户登陆商城创建添加</p>
					</el-form-item>
					<el-form-item label="配送方式" prop="express" :rules="{ required: true, message: '必须提交配送方式', trigger: 'blur' }">
						<el-select placeholder="请输入关键词搜索" v-model="cart_form.express">
							<el-option v-for="(express, e) in expresses" :key="e" :label="express" :value="e"></el-option>
						</el-select>
					</el-form-item>
				</template>
				<el-form-item label="配送信息" v-else required>当前重量为0克虚拟商品，不需要配送</el-form-item>
				<el-form-item label="支付方式" prop="is_balance" :rules="{ required: true, message: '必须选择支付方式', trigger: 'blur' }">
					<el-switch v-model="cart_form.is_balance" :active-text="'余额支付(可用余额￥'+(balance/100)+'元)'" inactive-text="用户支付" :active-value="true" :inactive-value="false" :disabled="balance<cart_form.goods_price"></el-switch>
					<p style="font-size: 12px; color: #999;">Tips：默认【用户支付】提交结算订单后尽快联系客户登陆商城支付。</p>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('form_closing')">提交结算</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="400px" title="批量复制" :visible.sync="dialog_copying" :before-close="clearCartForm">
			<el-form ref="form_copying" label-width="100px" :model="cart_form" :size="theme.size">
				<el-form-item label="目标用户" prop="user_id" :rules="{ required: true, message: '必须选择一个目标用户', trigger: 'blur' }">
					<el-select placeholder="请输入关键词搜索" v-model="cart_form.user_id" :remote-method="remoteUsers" :loading="remote_loading" remote filterable default-first-option>
						<el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary"  @click="submitForm('form_copying')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="600px" title="更新商品" :visible.sync="dialog_updating" :before-close="clearCartForm">
			<el-form ref="form_updating" label-width="80px" :model="cart_form" :size="theme.size">
				<el-form-item label="商品" prop="title">{{cart_form.title}} <template v-if="cart_form.goods">#{{cart_form.goods.number}}</template></el-form-item>
				<template v-if="cart_form.goods">
					<el-form-item label="类型" prop="goods_type">{{cart_form.goods.goods_type?cart_form.goods.goods_type.name:'单商品'}}</el-form-item>
					<template v-if="cart_form.all_attributes.length">
						<el-form-item :label="t_attr.name" :prop="'attributes.'+t_attr.id" :rules="[{ required: t_attr.required, message: '请选择必选项', trigger: 'blur' }]" v-for="t_attr in cart_form.all_attributes" :key="t_attr.id">
							<el-checkbox-group v-model="cart_form.attributes[t_attr.id]" v-if="cart_form.goods.goods_attributes.length" :max="t_attr.cross?1:t_attr.multiple_limit||cart_form.max_limit">
								<template v-for="g_attr in cart_form.goods.goods_attributes">
									<template v-if="t_attr.id === g_attr.type_attribute_id">
										<el-checkbox :label="g_attr.id" :key="g_attr.id" :disabled="!t_attr.cross && !g_attr.stock">{{g_attr.name}} <template v-if="!t_attr.cross"> #{{g_attr.number}} +{{g_attr.shop_price/100}} 剩{{g_attr.stock}}</template></el-checkbox>
									</template>
								</template>
							</el-checkbox-group>
						</el-form-item>
					</template>
				</template>
				<el-form-item label="数量" prop="count" :rules="[{ required: true, message: '必须输入下单数量', trigger: 'blur' }]">
					<el-input-number v-model="cart_form.count" :min="1" :max="cart_form.max_limit" label="下单数量"></el-input-number>
					<p v-if="cart_form.cross_attribute">{{cart_form.cross_attribute.name}} <template v-if="cart_form.cross_attribute.number">#{{cart_form.cross_attribute.number}} ￥{{cart_form.cross_attribute.shop_price/100}} 剩{{cart_form.cross_attribute.stock}}</template></p>
				</el-form-item>
				<el-form-item>
					<el-button type="primary"  @click="submitForm('form_updating')" :disabled="!cart_form.goods">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>	
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	
	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		watch: {
			'cart_form.attributes': {
				handler(n) {
					const { all_attributes, goods, count } = this.cart_form;
					if (!all_attributes || !goods) return false;
					var max_limit = 9999999999999999999999;
					var cross_join = [];
					var attributes = [];
					var cross_attribute = '';
					all_attributes.map((t) => {
						if (t.cross) {
							cross_join = [...cross_join, ...n[t.id]];
						} else {
							attributes = [...attributes, ...n[t.id]];
						}
					});
					if (!goods.goods_attributes) return false;
					// 遍历查找最小库存
					goods.goods_attributes.map((t) => {
						if ((t.attributes === cross_join.join('_') || (!t.attributes && attributes.indexOf(t.id) >= 0)) && max_limit > t.stock) {
							max_limit = t.stock;
							if (count > t.stock) {
								this.cart_form.count = t.stock;
							}
						}
						if (t.attributes === cross_join.join('_')) {
							cross_attribute = t;
						}
					});
					this.cart_form.max_limit = max_limit;
					this.cart_form.cross_attribute = cross_attribute;
				},
				deep: true,  // 是否深度监听
				immediate: true  // 页面加载立即执行
			}
		},
		methods: {
			async remoteUsers (query) {
				if (query !== '') {
					this.remote_loading = true;
					const res = await this.$http.get(this.$api.URI_SEARCHES, { params: {t: 'users', q: query} });
					this.users = res.data.result.map((i) => { return i; });
					this.remote_loading = false;
				}
			},
			async onAction (a) {
				const { selection } = this.$refs.carts_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				var user_id = 0;
				// 必须遍历检查选中的是统一用户才可以提交结算
				for (let i = 0; i < selection.length; i++) {
					if (user_id == 0) {
						user_id = selection[i].user.id;
					}
					if (user_id != selection[i].user.id) return this.$message.warning('选中商品中的用户不一致！');
				}
				switch (a) {
					case 'closing':
						var weight = 0,
							user_name = '',
							goods_count = 0,
							goods_total = 0,
							goods_price = 0;
						// 必须遍历检查选中的是统一用户才可以提交结算
						for (let i = 0; i < selection.length; i++) {
							user_name = selection[i].user.name;
							if (selection[i].deleted_at) return this.$message.warning(selection[i].title+'商品已删除！');
							goods_count += 1;
							goods_total += selection[i].count;
							goods_price += selection[i].count*selection[i].shop_price;
							if (selection[i].goods) {
								weight += selection[i].goods.weight;
							}
						}
						this.cart_form = {
							...this.cart_form,
							shop: this.$route.query.no,
							action: 'closing',
							is_balance: false,
							carts: selection.map((item) => { return item.id }),
							weight: weight,
							user_id: user_id,
							user_name: user_name,
							goods_count: goods_count,
							goods_total: goods_total,
							goods_price: goods_price
						};
						if (weight) {
							var res = await this.$http.get(this.$api.URI_CARTS, {params: {action: 'closing', shop: this.$route.query.no, user_id: user_id}, headers: {loading: true}});
							var { code, msg, result } = res.data;
							if (code != 0) return this.$message.error(msg);
							this.balance = result.balance;
							this.expresses = result.expresses;
							this.addresses = result.addresses;
						}
						this.dialog_closing = true;
						break;
					case 'copying':
						this.cart_form = { ...this.cart_form, shop: this.$route.query.no, action: 'copying', carts: selection.map((item) => { return item.id }) };
						this.dialog_copying = true;
						break;
					case 'export':
						selection.map((item) => { item.user_name = item.user.name; });
						this.$confirm(`此操作将导出${selection.length}条内容, 是否继续?`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							var file = "购物车明细导出" + this.$moment().format('YYYYMMDDHHmmss');
							var header = [['ID', '用户名称', '商品名称', '单价', '数量', '删除时间', '更新时间', '创建时间']];
							var filter = ['id', 'user_name', 'title', 'shop_price', 'count', 'deleted_at', 'updated_at', 'updated_at'];
							this.$utils.handleDownload(header, filter, selection, file, "购物车明细");
						})
						break;
					case 'delete':
						this.$confirm(`确定要删除选中的${selection.length}条记录吗？`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							selection.filter((item) => { return item.deleted_at === null });
							this.submitCart({ action: 'delete', shop: this.$route.query.no, user_id: user_id, carts: selection.map((item) => { return item.id }) });
						});
						break;
					case 'restore':
						this.$confirm(`确定要恢复选中的${selection.length}条记录吗？`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							selection.filter((item) => { return item.deleted_at !== null });
							this.submitCart({ action: 'restore', shop: this.$route.query.no, user_id: user_id, carts: selection.map((item) => { return item.id }) });
						});
						break;
				}
			},
			onEdit (r) {
				if (!r.goods) return this.$message.error('此商品已删除！');
				const { goods_type, type_attributes, goods_attributes } = r.goods;
				// 转换已选择ID为数组
				var attribute_id = r.attribute_id?r.attribute_id.split(','):[];
				// 初始化回显数据
				var attributes = {};
				var max_limit = 9999999;
				// 合并类型属性和商品属性
				let all_attributes = goods_type?goods_type.type_attributes:[];
					all_attributes = [...all_attributes, ...type_attributes];
				// 遍历属性值，
				for (let i = 0; i < goods_attributes.length; i++) {
					// 查找已选择属性是否存在笛卡尔积，并转为数组并合并到已选择属性ID
					if (goods_attributes[i].attributes && attribute_id.indexOf(goods_attributes[i].id.toString()) >= 0) {
						max_limit = goods_attributes[i].stock;
						attribute_id = [...attribute_id, ...goods_attributes[i].attributes.split('_')];
					}
				}
				// 遍历属性名
				for (let i = 0; i < all_attributes.length; i++) {
					var checked = [];
					// 遍历属性值，查找当前属性名下的属性值并存在已选择的属性，存储到回显对象中
					for (let j = 0; j < goods_attributes.length; j++) {
						if (all_attributes[i].id == goods_attributes[j].type_attribute_id && attribute_id.indexOf(goods_attributes[j].id.toString()) >= 0) {
							checked.push(goods_attributes[j].id);
							if (goods_attributes[i].stock > 0 && max_limit > goods_attributes[i].stock) {
								max_limit = goods_attributes[i].stock;
							}
						}
					}
					attributes[all_attributes[i].id] = checked;
				}
				this.cart_form = { ...r, shop: this.$route.query.no, action: 'update', all_attributes: all_attributes, attributes: attributes, max_limit: max_limit };
				this.dialog_updating = true;
			},
			onDelete (r) {
				this.$confirm(`确定要删除【${r.title}】商品吗？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CARTS, {params: {action: 'delete', shop: this.$route.query.no, id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getCarts(this.params);
						}
					});
				})
			},
			reStore (r) {
				this.$confirm(`确定要恢复【${r.title}】商品吗？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CARTS, {params: {action: 'restore', shop: this.$route.query.no, id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getCarts(this.params);
						}
					});
				})
			},
			clearCartForm (done) {
				this.cart_form = {};
				done();
			},
			clearCartConfirm (done) {
				this.$confirm('确认关闭？').then(() => {
					this.cart_form = {};
					done();
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					if (ref === 'form_updating') {
						let attribute_id = [];
						const { cross_attribute, all_attributes, attributes, id, user_id, count, shop, action } = this.cart_form;
						cross_attribute.id && attribute_id.push(cross_attribute.id);
						all_attributes.map((t) => {
							if (!t.cross) {
								attribute_id = [...attribute_id, ...attributes[t.id]];
							}
						});
						return this.submitCart({id, user_id, shop, count, action, attribute_id: attribute_id.join(',')});
					}
					this.submitCart(this.cart_form);
				})
			},
			async submitCart (data) {
				const res = await this.$http.post(this.$api.URI_CARTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.dialog_closing) {
							this.dialog_closing = false;
						}
						if (this.dialog_copying) {
							this.dialog_copying = false;
						}
						if (this.dialog_updating) {
							this.dialog_updating = false;
						}
						this.getCarts(this.params);
					}
				})
			},
			async getCarts (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CARTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.carts = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				balance: 0,
				carts: [],
				users: [],
				shops: [],
				expresses: [],
				addresses: [],
				dialog_closing: false,
				dialog_copying: false,
				dialog_updating: false,
				remote_loading: false,
				cart_form: {},
				params: {
					perPage: 10,
				}
			}
		},
		async created () {
			let { query } = this.$route;
			this.getCarts({...this.params, shop: query.no}, true);
		}
	};
</script>